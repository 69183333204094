import { useUserInfoStore } from '~/stores/userInfoStore'
import {modal} from '@tian-libs/modal'

export const getUserInfo = async () => {
    try{
        const response = await fetch2.get('/user/info', {})
        const userInfo = response.data.data
        useUserInfoStore().info = userInfo
        return userInfo
    }catch(e){
        if (e.response.status == 401){
            modal.alert('请先登录', () => {
                window.location.href = '/login'
            })
        }
    }
}