import axios from 'axios'
import {getMenuLeaf} from '~/menus/menus'
import {getSignedFilePaths} from '~/utils/getSignedFilePaths'
import { pinyinSort } from '~/utils/pinyinSort'

const cache = {} as  any

const adOptions = [
    "城市",
    "品牌",
    "设计",
    "采购",
    "项目",
    "预算审核",
    "业绩评估",
    "经营提升"
]


const huameiServices = [
    "1.华美服务@【城市名】",
    "10.经营提升",
    "11.人力资源",
    "12.资产保值增值",
    "13.集团服务",
    "14.酒店+",
    "2.定位可研",
    "3.品牌引进",
    "4.设计管理",
    "5.采购管理",
    "6.开业筹备",
    "7.业主代表",
    "8.预算审核",
    "9.业绩评估",
]
const huameiServiceFolders = {
    "1.华美服务@【城市名】":"【隐藏】华美服务@【城市名】",
    "10.经营提升":"【隐藏】经营提升",
    "11.人力资源":"【隐藏】人力资源",
    "12.资产保值增值":"【隐藏】资产保值增值",
    "13.集团服务":"【隐藏】集团服务",
    "14.酒店+":"【隐藏】酒店+",
    "2.定位可研":"【隐藏】定位可研",
    "3.品牌引进":"【隐藏】品牌引进",
    "4.设计管理":"【隐藏】设计管理",
    "5.采购管理":"【隐藏】采购管理",
    "6.开业筹备":"【隐藏】开业筹备",
    "7.业主代表":"【隐藏】业主代表",
    "8.预算审核":"【隐藏】预算审核",
    "9.业绩评估":"【隐藏】业绩评估",
}
const huameiServicesSuffix = {
    "1.华美服务@【城市名】":"华美服务@【城市名】",
    "10.经营提升":"经营提升",
    "11.人力资源":"人力资源",
    "12.资产保值增值":"资产",
    "13.集团服务":"集团",
    "14.酒店+":"酒店+",
    "2.定位可研":"可研",
    "3.品牌引进":"品牌引进",
    "4.设计管理":"设计管理",
    "5.采购管理":"采购管理",
    "6.开业筹备":"开筹",
    "7.业主代表":"业主代表",
    "8.预算审核":"预算审核",
    "9.业绩评估":"业绩评估",
}


export const getAdFileType = (
    _category1:string, 
    nodePath: string,
) => {
    let category1 = _category1
    if(!["城市","品牌"].includes(category1)){
        if(nodePath){

            const [_empty, _nrwj, _cat1, _target, ...res] = nodePath.split('/') 

            if(!_target){
                return ""
            }

            for(let c of adOptions){
                if(_target.includes(c)){
                    category1 = c
                    break
                }
            }
        }else{
            category1 = ""
        }
    }
    return category1
}

export const getAdFiles = (
    _category1:string, 
    _instance:string,
    nodePath: string,
) => {

    console.log("getAdFiles v3Utils _category1", _category1, _instance, nodePath)

    let category1 = _category1
    if(!["城市","品牌",'华美服务'].includes(category1)){
        if(nodePath){

            const [_empty, _nrwj, _cat1, _target, ...res] = nodePath.split('/') 

            if(!!_target){
                for(let c of adOptions){
                    if(_target.includes(c)){
                        category1 = c
                        break
                    }
                }
            }

        }else{
            category1 = ""
        }
    }

    let instance = _instance
    if(
        instance.match(/华美服务@/)
        && !instance.match(/华美服务@【城市名】/)
    ){
        instance = instance.replace(/华美服务@/,'')
    }


    console.log("getAdFiles category1", category1, instance, nodePath)

    return new Promise<{
        projects:{display:string}[],
        documents:any[],
        relatedDocs:any[],
    }>(async (resolve, reject) => {
        switch(category1){
            case '项目':{
                const projects = []
                const documents = []
                resolve({projects, documents, relatedDocs:[]})
                break
            }
            case '城市':{
                if(!instance){
                    const projects = []
                    const documents = [] as {display:string}[]
                    resolve({projects, documents, relatedDocs:[]})
                }else{
                    const projectsURL = `/内容文件/1.项目/4.华美服务/【隐藏】华美服务@【城市名】/1.华美服务项目清单/${instance}.json`
                    const documentsURL = `/内容文件/1.项目/4.华美服务/【隐藏】华美服务@【城市名】/2.华美报告/${instance}`
                    const relatedDocsURL = `/内容文件/1.项目/4.华美服务/【隐藏】华美服务@【城市名】/3.相关资料/${instance}`
                    Promise.all([
                        getSignedFilePaths([projectsURL]),
                        getMenuLeaf(documentsURL),
                        getMenuLeaf(relatedDocsURL),
                    ]).then(async ([signedProjectURLS, documents, relatedDocs]) => {
                        try{
                            const projects  = await axios.get("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/" + signedProjectURLS[projectsURL])
                                                        .then((res) => res.data)
                            const projectNameKey = Object.keys(projects[0]).find(x=>x.includes("项目名称"))

                            const docuChildrenUnique = []
                            for(let doc of documents.children){
                                if(!docuChildrenUnique.find(x=>x.display == doc.display)){
                                    docuChildrenUnique.push(doc)
                                }
                            }
                            const relDocChildrenUnique = []
                            for(let doc of relatedDocs.children){
                                if(!relDocChildrenUnique.find(x=>x.display == doc.display)){
                                    relDocChildrenUnique.push(doc)
                                }
                            }
                            resolve({
                                projects: projects
                                        .map((x:any)=>({display: x[projectNameKey]}))
                                        .sort((a,b)=>pinyinSort(a.display,b.display)),
                                documents:  docuChildrenUnique
                                        .sort((a,b)=>pinyinSort(a.display,b.display)),
                                relatedDocs: relDocChildrenUnique
                                        .sort((a,b)=>pinyinSort(a.display,b.display))
                            })
                        }catch(e){
                            console.log("getAdFiles Failed to get projects", e)
                            resolve({projects: [], documents: [], relatedDocs:[]})
                        }
                    })
                    return
                }
                break
            }
            case '华美服务':{
                let targetServiceDir = ""
                for(let service of huameiServices){
                    if(service.includes(instance)){
                        targetServiceDir = service
                        break
                    }
                }

                console.log('targetServiceDir instance', instance)
                console.log('targetServiceDir', targetServiceDir)

                const projectsURL = `/内容文件/1.项目/4.华美服务/${huameiServiceFolders[targetServiceDir]}/1.华美服务项目清单/华美项目完整清单（${huameiServicesSuffix[targetServiceDir]}）.json`
                const documentsURL = `/内容文件/1.项目/4.华美服务/${huameiServiceFolders[targetServiceDir]}/2.华美报告`
                const relatedDocsURL = `/内容文件/1.项目/4.华美服务/${huameiServiceFolders[targetServiceDir]}/3.相关资料`
                Promise.all([
                    getSignedFilePaths([projectsURL]),
                    getMenuLeaf(documentsURL),
                    getMenuLeaf(relatedDocsURL),
                ]).then(async ([signedProjectURLS, documents, relatedDocs]) => {
                    try{

                        console.log("getAdFiles documents", documents)
                        
                        const projects  = await axios.get("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/" + signedProjectURLS[projectsURL])
                                                    .then((res) => res.data)
                        const projectNameKey = Object.keys(projects[0]).find(x=>x.includes("项目名称"))

                        const docuChildrenUnique = []
                        for(let doc of documents.children){
                            if(!docuChildrenUnique.find(x=>x.display == doc.display)){
                                docuChildrenUnique.push(doc)
                            }
                        }
                        const relDocChildrenUnique = []
                        for(let doc of relatedDocs.children){
                            if(!relDocChildrenUnique.find(x=>x.display == doc.display)){
                                relDocChildrenUnique.push(doc)
                            }
                        }
                        resolve({
                            projects: projects
                                        .map((x:any)=>({display: x[projectNameKey]}))
                                        .sort((a,b)=>pinyinSort(a.display,b.display)),
                            documents:  docuChildrenUnique
                                        .sort((a,b)=>pinyinSort(a.display,b.display)),
                            relatedDocs: relDocChildrenUnique
                                        .sort((a,b)=>pinyinSort(a.display,b.display))
                        })
                    }catch(e){
                        console.log("getAdFiles Failed to get projects", e)
                        resolve({projects: [], documents: [], relatedDocs:[]})
                    }
                })
                return
            }
            case '':{
                const projects = getDefaultProjects()
                const documents = getDefaultDocuments() as {display:string}[]
                resolve({projects, documents, relatedDocs:[]})
                break
            }
        }
    })
}

const getDefaultProjects = () => {
    return []
}

const getDefaultDocuments = () => {
    return []
}