import axios from 'axios'

const prodBaseURL = window.location.protocol === 'https:' 
  ? 'https://api.c.huamei2001.com/api/v1'
  : 'http://api.c.huamei2001.com/api/v1'

const localBaseURL = 'http://localhost:8080/api/v1'
// const localBaseURL = 'http://api.c.huamei2001.com/api/v1'

export const fetch2 = {
    get: async (url:string, configs:any) => {
        const jwt = useCookie('jwt')
        const baseURL = process.env.NODE_ENV === 'development' ? localBaseURL : prodBaseURL
        return await axios.get(baseURL + url, {
            ...configs,
            headers: {
                'Authorization': `Bearer ${jwt.value}`
            }
        })
    },
    post: async (url:string, body:any) => {
        const jwt = useCookie('jwt')
        const baseURL = process.env.NODE_ENV === 'development' ? localBaseURL : prodBaseURL
        return await axios.post(baseURL + url, body, {
            headers: {
                'Authorization': `Bearer ${jwt.value}`
            }
        })
    }
}